<template>
  <am-form-layout
    title="Company"
    submit-text="Save"
    :show-loading="updatingForm"
    :show-reset="false"
    submit-button-name="update-school"
    @onSubmit="updateCompany()"
  >
    <template slot="form-header">
      <div class="d-flex justify-content-center mb-2">
        <div class="d-flex flex-column justify-content-start">
          <div class="text-center mb-2">
            <am-profile-photo
              v-show="!updatingLogo && logoUrl"
              size="80"
              :photo="logoUrl"
            />
            <b-avatar
              v-show="!updatingLogo && !logoUrl"
              size="80px"
              variant="light-primary"
            />
            <b-spinner
              v-show="updatingLogo"
              style="width: 80px; height: 80px;"
              variant="primary"
            />
          </div>
          <am-file-button
            label="Logo"
            :loading="updatingLogo"
            @onFileUpload="uploadLogo"
          />
        </div>
      </div>

    </template>
    <template slot="form-elements">
      <div class="col-md-4">
        <am-input
          v-model="company.name"
          name="name"
          label="Name"
          rules="required"
        />
      </div>
      <div class="col-md-4">
        <am-input
          v-model="company.address"
          name="address"
          label="Address"
          rules="required"
        />
      </div>
      <div class="col-md-4">
        <am-input
          v-model="company.postal_code"
          name="postal_code"
          label="Postal Code"
          rules="required"
        />
      </div>
      <div class="col-md-4">
        <am-input
          v-model="company.vat"
          name="vat"
          label="Vat"
          rules="required"
        />
      </div>
      <div class="col-md-4">
        <am-input
          v-model="company.registration_no"
          name="registration_no"
          label="Registration Number"
          rules="required"
        />
      </div>
      <div class="col-md-4">
        <am-input
          v-model="company.phone"
          name="phone"
          label="Phone"
          rules="required|max_length:11"
        />
      </div>
      <div class="col-md-4">
        <am-input
          v-model="company.email"
          name="email"
          label="Email"
          rules="required|email"
        />
      </div>
      <div class="col-md-4">
        <am-input
          v-model="company.website"
          name="website"
          label="Website"
        />
      </div>
      <div class="col-md-4">
        <am-select
          v-model="company.company_classification_id"
          :options="classifications"
          name="classification"
          label-name="name"
          value-name="id"
          label="Classification"
        />
      </div>
      <div class="col-12">
        <error-display :error="error" />
      </div>
    </template>
  </am-form-layout>
</template>

<script>
import { BAvatar, BSpinner } from 'bootstrap-vue'
import AmFormLayout from '@/farmbusiness/components/layouts/AmFormLayout'
import AmProfilePhoto from '@/farmbusiness/components/AmProfilePhoto'
import AmFileButton from '@/farmbusiness/components/AmFileButton'
import AmInput from '@/farmbusiness/components/AmInput'
import ErrorDisplay from '@/farmbusiness/components/AmErrorDisplay'
import AmSelect from '@/farmbusiness/components/AmSelect'
import showToast from '@/farmbusiness/mixins/showToast'

export default {
  name: 'Company',
  components: {
    AmSelect,
    ErrorDisplay,
    AmInput,
    AmFileButton,
    AmProfilePhoto,
    AmFormLayout,
    BAvatar,
    BSpinner,
  },
  mixins: [showToast],
  data() {
    return {
      company: {
        name: null,
        address: null,
        postal_code: null,
        vat: null,
        registration_no: null,
        email: null,
        phone: null,
        website: null,
        company_classification_id: null,
      },
      updatingLogo: false,
      updatingForm: false,
      error: {},
    }
  },
  computed: {
    logoUrl() {
      return this.$store.getters['company/companyLogo']
    },
    classifications() {
      return this.$store.getters['company/classifications']
    },
    companyDetails() {
      return this.$store.getters['company/company']
    },
  },
  mounted() {
    this.company = { ...this.companyDetails }
  },
  methods: {
    updateCompany() {
      this.updatingForm = true
      delete this.company.logo
      this.$store.dispatch('company/updateCompany', this.company)
        .then(() => {
          this.showSuccess('Updated Successfully')
        })
        .catch(error => {
          this.error = error
          this.showError('Failed to update company details')
        }).finally(() => {
          this.updatingForm = false
        })
    },
    uploadLogo(log) {
      const logo = new FormData()
      logo.append('logo', log.file)
      this.updatingLogo = true
      this.$store.dispatch('company/updateLogo', logo)
        .then(() => {
          this.showSuccess('Logo Updated Successfully')
        })
        .catch(() => {
          this.showError('Failed to update logo')
        }).finally(() => {
          this.updatingLogo = false
        })
    },
  },
}
</script>

<style scoped>

</style>
