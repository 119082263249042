<template>
  <div>
    <company />
    <div class="mt-5" />
    <currency />
    <div class="mt-5" />
    <configuration />
  </div>
</template>

<script>
import Company from '@/views/administration/company/Company.vue'
import Currency from '@/views/administration/company/Currency.vue'
import Configuration from '@/views/administration/company/Configuration.vue'

export default {
  components: { Configuration, Currency, Company },
  computed: {
  },
  mounted() {
    this.fetchClassifications()
  },
  methods: {
    fetchClassifications() {
      this.$store.dispatch('company/fetchClassifications')
    },
  },
}
</script>
