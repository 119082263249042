<template>
  <am-form-layout
    title="Currency"
    submit-text="Save"
    :show-loading="updatingForm"
    :show-reset="false"
    submit-button-name="update-currency"
    @onSubmit="updateCurrency()"
  >
    <template slot="form-elements">
      <div class="col-md-4">
        <am-input
          v-model="currency.name"
          name="name"
          label="Name"
          rules="required"
        />
      </div>
      <div class="col-md-4">
        <am-input
          v-model="currency.symbol"
          name="symbol"
          label="Symbol"
          rules="required"
        />
      </div>
      <div class="col-md-4">
        <am-input
          v-model="currency.abbreviation"
          name="abbreviation"
          label="Abbreviation"
          rules="required"
        />
      </div>
      <div class="col-12">
        <error-display :error="error" />
      </div>
    </template>
  </am-form-layout>
</template>

<script>
import AmInput from '@/farmbusiness/components/AmInput'
import ErrorDisplay from '@/farmbusiness/components/AmErrorDisplay'
import showToast from '@/farmbusiness/mixins/showToast'
import AmFormLayout from '@/farmbusiness/components/layouts/AmFormLayout'

export default {
  name: 'Currency',
  components: { AmFormLayout, ErrorDisplay, AmInput },
  mixins: [showToast],
  data() {
    return {
      error: {},
      currency: {
        name: '',
        symbol: '',
        abbreviation: '',
      },
      updatingForm: null,
    }
  },
  computed: {
    currencyDetails() {
      return this.$store.getters['company/currency']
    },
  },
  mounted() {
    this.currency = { ...this.currencyDetails }
  },
  methods: {
    updateCurrency() {
      this.updatingForm = true
      this.$store.dispatch('company/updateCurrency', this.currency)
        .then(() => {
          this.showSuccess('Updated Successfully')
        })
        .catch(error => {
          this.error = error
          this.showError('Failed to update company currency')
        }).finally(() => {
          this.updatingForm = false
        })
    },
  },
}
</script>

<style scoped>

</style>
