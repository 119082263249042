<template>
  <b-overlay
    :show="showLoading"
    :variant="darkMode?'dark':'white'"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="md"
  >
    <validation-observer
      v-slot="{invalid}"
      :ref="formRef"
    >
      <b-card :title="title">
        <b-form @submit.prevent>
          <slot name="form-header" />
          <b-row>
            <slot name="form-elements" />
          </b-row>
          <slot name="form-actions">
            <b-row class="float-right mt-1">
              <b-col cols="12">
                <am-button
                  v-if="showReset"
                  variant="warning"
                  icon="RefreshCcwIcon"
                  class="mr-1"
                  :label="resetText"
                  @click="handleResetClick"
                />
                <am-button
                  v-if="showSubmit"
                  :name="submitButtonName"
                  :label="submitText"
                  :disabled="createDisabled || invalid"
                  @click="handleSubmitClick"
                />
              </b-col>
            </b-row>
          </slot>
        </b-form>
      </b-card>
    </validation-observer>
  </b-overlay>
</template>

<script>
import {
  BRow, BCol, BForm, BCard, BOverlay,
} from 'bootstrap-vue'
import { createNamespacedHelpers } from 'vuex'
// eslint-disable-next-line import/extensions
import { ValidationObserver } from 'vee-validate'
import useAppConfig from '@core/app-config/useAppConfig'
import eventBus from '@/farmbusiness/utils/eventBus'
import AmButton from '@/farmbusiness/components/AmButton.vue'

const { mapState, mapMutations } = createNamespacedHelpers('app')
const { skin } = useAppConfig()
export default {
  name: 'AmFormLayout',
  components: {
    AmButton,
    ValidationObserver,
    BRow,
    BCol,
    BForm,
    BCard,
    BOverlay,
  },
  props: {
    submitButtonName: { type: String, default: null },
    submitText: { type: String, default: 'Create' },
    resetText: { type: String, default: 'Reset' },
    title: { type: String, default: 'Create Form' },
    createDisabled: { type: Boolean, default: false },
    showReset: { type: Boolean, default: true },
    showSubmit: { type: Boolean, default: true },
    showLoading: { type: Boolean, default: false },
    formRef: { type: String, default: 'fbFormLayout' },
  },
  data() {
    return {
      // show: false,
    }
  },
  computed: {
    ...mapState(['currentFormRef']),
    darkMode() {
      return skin.value === 'dark'
    },
  },
  mounted() {
    this.setCurrentFormRef(this.formRef)
    eventBus.$on('invalidFormData', res => {
      if (res.validationError) {
        this.$refs[this.currentFormRef].setErrors(res.errors)
      }
    })
    eventBus.$on('submitSuccess', (created = false) => {
      if (created) {
        this.$refs[this.currentFormRef].reset()
      }
    })
  },
  destroyed() {
    eventBus.$off(['submitSuccess', 'invalidFormData'])
  },
  methods: {
    ...mapMutations(['setCurrentFormRef']),
    handleSubmitClick() {
      this.$emit('onSubmit')
    },
    handleResetClick() {
      this.$refs[this.currentFormRef].reset()
      this.$emit('onReset')
    },
  },
}
</script>
