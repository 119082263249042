<template>
  <am-form-layout
    title="Configuration"
    submit-text="Save"
    :show-loading="updatingForm"
    :show-reset="false"
    submit-button-name="update-configuration"
    @onSubmit="updateConfiguration()"
  >
    <template slot="form-elements">
      <div class="col-md-4">
        <am-input
          v-model="configuration.sender"
          name="sms_sender_name"
          label="Sms Sender Name"
          rules="required"
          readonly
        />
      </div>
      <div class="col-md-4">
        <am-input
          v-model="configuration.key"
          name="key"
          label="Hubtel Key"
          rules="required"
        />
      </div>
      <div class="col-md-4">
        <am-input
          v-model="configuration.secret"
          name="secret"
          label="Hubtel Secret"
          rules="required"
        />
      </div>
      <div class="col-12">
        <error-display :error="error" />
      </div>
    </template>
  </am-form-layout>
</template>

<script>
import AmInput from '@/farmbusiness/components/AmInput'
import ErrorDisplay from '@/farmbusiness/components/AmErrorDisplay'
import showToast from '@/farmbusiness/mixins/showToast'
import AmFormLayout from '@/farmbusiness/components/layouts/AmFormLayout'

export default {
  name: 'Configuration',
  components: { AmFormLayout, ErrorDisplay, AmInput },
  mixins: [showToast],
  data() {
    return {
      error: {},
      configuration: {
        sender: '',
        key: '',
        secret: '',
      },
      updatingForm: null,
    }
  },
  computed: {
    configurationDetails() {
      return this.$store.getters['company/configuration']
    },
  },
  mounted() {
    this.configuration = { ...this.configurationDetails }
  },
  methods: {
    updateConfiguration() {
      this.updatingForm = true
      this.$store.dispatch('company/updateConfiguration', this.configuration)
        .then(() => {
          this.showSuccess('Updated Successfully')
          this.configuration.key = null
          this.configuration.secret = null
        })
        .catch(error => {
          this.error = error
          this.showError('Failed to update company configuration')
        }).finally(() => {
          this.updatingForm = false
        })
    },
  },
}
</script>

<style scoped>

</style>
